const Footer = () => {
  return (
    <>
      <div className="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in">
        <a className="text-white no-underline hover:no-underline" href="#">2022 &copy; Todak Arena. All Rights Reserved</a>
      </div>
    </>
  )
}
  
export default Footer;