import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const MasterLayout = () => {

  return (
    <>
      <div className="leading-normal tracking-normal text-gray-900">
        <div className="min-h-screen pb-14 bg-center bg-cover bg-fixed" style={{ backgroundImage: "url('todak-arena-bg-min.jpg')" }}>  
          <Header />
          <div className="container px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default MasterLayout;