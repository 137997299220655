import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mstGeneral } from '../mobx';

const MENU_ITEMS = [
  { id: 1, slug: 'home', url: '/', name_en: 'Home', name_my: 'Utama' },
  // { id: 2, slug: 'about', url: '/about', name: 'About' },
  {
    id: 3,
    slug: 'privacy-policy',
    url: '/privacy-policy',
    name_en: 'Privacy Policy',
    name_my: 'Polisi Privasi',
  },
  {
    id: 4,
    slug: 'terms-of-use',
    url: '/terms-of-use',
    name_en: 'Terms Of Use',
    name_my: 'Terma',
  },
];

const Header = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  console.log('check currentUrl ===> ', currentUrl);

  const isActive = (url) => {
    if (currentUrl === url) {
      return true;
    } else {
      return false;
    }
  };

  const onClick = (url) => {
    navigate(url);
  };

  const onChangeLang = (lang) => {
    console.log('check onChangeLang ===> ', lang);
    mstGeneral.onChangeLang({ lang: lang });
  };

  return (
    <>
      <div className="w-full container mx-auto p-6">
        <div className="w-full md:flex items-center justify-between">
          <a
            className="flex justify-center items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl md:py-6"
            href="/"
          >
            <img
              src="todak-arena-logo-min.png"
              className="w-20"
              width={'12%'}
            />
          </a>
          <div className="md:flex justify-center items-center">
            <div className="mb-4 md:mb-0">
              <div className="flex mt-4 md:mt-0 md:w-100 w-100 justify-center md:justify-end content-center">
                {MENU_ITEMS.map((item, itemIndex) => {
                  return (
                    <a
                      className={`cursor-pointer inline-block text-sm text-white no-underline hover:text-gray-200 text-center py-2 px-4 ${
                        isActive(item.url) ? 'bg-gray-600/30' : ''
                      } rounded`}
                      onClick={() => {
                        onClick(item.url);
                      }}
                    >
                      {getSnapshot(mstGeneral).currentLang === 'en'
                        ? item.name_en
                        : item.name_my}
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-center md:justify-start items-center text-white mb-0 mt-0 md:ml-8">
              <div
                onClick={() => {
                  onChangeLang('my');
                }}
                style={{
                  opacity:
                    getSnapshot(mstGeneral).currentLang !== 'en' ? 1 : 0.5,
                }}
                className={`cursor-pointer hover:opacity-100`}
              >
                <div className="border-r-2 pr-4">
                  <img
                    src="https://cdn-a.todakarena.com/public/flag-of-Malaysia.png"
                    className="h-4"
                    alt="malaysia"
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  onChangeLang('en');
                }}
                style={{
                  opacity:
                    getSnapshot(mstGeneral).currentLang === 'en' ? 1 : 0.5,
                }}
                className={`cursor-pointer hover:opacity-100`}
              >
                <div className="pl-4">
                  <img
                    src="https://cdn-a.todakarena.com/public/flag-of-United-Kingdom.png"
                    className="h-4"
                    alt="united-kingdom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Header;
