import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { IoLanguage } from 'react-icons/io5';
import { mstGeneral } from '../../mobx';

const Home = observer(() => {
  const [aboutMyData, setAboutMyData] = useState({});
  const [aboutEnData, setAboutEnData] = useState({});

  useEffect(() => {
    mstGeneral
      .fetchAbout({ lang: 'my' })
      .then((response) => {
        setAboutMyData(response);
        // setIsLoading(false)
      })
      .catch((error) => {
        // setIsLoading(false)
      });
    mstGeneral
      .fetchAbout({ lang: 'en' })
      .then((response) => {
        setAboutEnData(response);
        // setIsLoading(false)
      })
      .catch((error) => {
        // setIsLoading(false)
      });
  }, []);

  const aboutData =
    getSnapshot(mstGeneral).currentLang === 'en' ? aboutEnData : aboutMyData;

  return (
    <>
      <div className="pt-6 md:pt-12 lg:pt-0 flex flex-col w-full xl:w-3/6 justify-center lg:items-start overflow-y-hidden">
        <h1
          className="mb-6 md:my-6 text-4xl md:text-5xl font-bold leading-tight text-center md:text-left slide-in-bottom-h1 text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-600"
          style={{ fontFamily: 'Audiowide, helvetica' }}
        >
          TODAK ARENA
        </h1>
        <p
          className="leading-normal text-white w-10/11 mx-auto md:mx-0 text-md md:text-md text-center md:text-left slide-in-bottom-subtitle mb-8 md:mb-8 px-5 md:px-0"
          dangerouslySetInnerHTML={{ __html: aboutData.content }}
        />
        {/* <div className="flex justify-center md:justify-start items-center text-white mb-10 mt-5">
          <IoLanguage size={25} className="mr-4" />
          <a onClick={() => { onChangeLang("my") }} className={`cursor-pointer opacity-${!isEng ? '100' : '20'} hover:opacity-100`}>
            <div className="border-r-2 pr-4"><img src="https://www.countries-ofthe-world.com/flags-normal/flag-of-Malaysia.png" className="h-4" /></div>
          </a>
          <a onClick={() => { onChangeLang("en") }} className={`cursor-pointer opacity-${isEng ? '100' : '20'} hover:opacity-100`}>
            <div className="pl-4"><img src="https://www.countries-ofthe-world.com/flags-normal/flag-of-United-States-of-America.png" className="h-4" /></div>
          </a>
        </div> */}
        {/* <button className="hover:text-gray-200 text-white mt-6 mb-12 flex items-center">Learn More <FiArrowRight size={18} className="ml-2" /></button> */}
        {/* <button className="hover:text-gray-200 text-white mt-6 mb-10 flex items-center"><FiPlayCircle size={22} className="mr-2" /> Watch Video</button> */}
        <p className="text-blue-300 pb-4 md:pb-6 text-center md:text-left fade-in mt-2">
          {getSnapshot(mstGeneral).currentLang === 'en'
            ? 'Download and join the community now!'
            : 'Muat turun dan sertai komuniti sekarang!'}
        </p>
        <div className="flex w-full justify-center md:justify-start pb-12 md:pb-24 fade-in">
          <a
            href="https://apps.apple.com/my/app/todak-arena/id1636642926"
            target={'_blank'}
          >
            <img src="app-store.svg" className="h-12 pr-4 bounce-top-icons" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.todak.arena"
            target={'_blank'}
          >
            <img src="play-store.svg" className="h-12 bounce-top-icons" />
          </a>
        </div>
      </div>
      <div className="w-full xl:w-3/6 py-0 md:py-6 lg:pt-0 overflow-y-hidden">
        <img
          fetchPriority="high"
          className="mx-auto lg:mr-0 slide-in-bottom"
          src="todak-arena-phone-ss-min.png"
        />
      </div>
    </>
  );
});

export default Home;
