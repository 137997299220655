import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MasterLayout from './components/MasterLayout';
import Home from './pages/home';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import About from './pages/about';
import { useEffect } from 'react';

function App() {

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='privacy-policy' element={<Privacy />} />
          <Route path='terms-of-use' element={<Terms />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
